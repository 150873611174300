import React from "react";
import Resource from "./resource";
import imgSrc from "images/thumbnail-doctor-discussion.png";

export default function index() {
  return (
    <Resource
      title="Doctor Discussion Guide"
      description="Download this tool to help aid in your conversation with your physician."
      thumbnail={imgSrc}
      altText="Doctor Discussion Guide"
    >
      <a
        href='https://d1skd172ik98el.cloudfront.net/48a33315-f594-4269-8043-8853d10fb7bf/3e6e3e2b-90e9-4c7f-965a-b7cbf228b579/3e6e3e2b-90e9-4c7f-965a-b7cbf228b579_source__v.pdf'
        target="_blank"
        className="aveed-button green resource-button"
        rel="noopener noreferrer"
      >
        Download Discussion Guide
      </a>
    </Resource>
  );
}
