import React from 'react';
import Resource from './resource';
import imgSrc from 'images/thumbnail-pat-broch.png'

export default function index() {
  return (
    <Resource
      title="AVEED<sup>&reg;</sup> Patient Brochure"
      description="Download the AVEED<sup>&reg;</sup> Patient Brochure to learn more about hypogonadism, treating hypogonadism with AVEED<sup>&reg;</sup>, and how to save on your AVEED<sup>&reg;</sup> therapy."
      thumbnail={imgSrc}
      altText="AVEED® Patient Brochure"
    >
      <a href='https://d1skd172ik98el.cloudfront.net/48a33315-f594-4269-8043-8853d10fb7bf/013a795b-4679-488e-b082-9d609631dacc/013a795b-4679-488e-b082-9d609631dacc_source__v.pdf' target="_blank" className="aveed-button green resource-button" rel="noopener noreferrer">Download Brochure</a>
    </Resource>
  )
}
